//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const german = {
    and:                     'und',
    appHeadline:             'F1',
    appointmentType:         'Termin-Art',
    appointments:            'Termine',
    countDownDay:            'Tag',
    countDownDays:           'Tage',
    countDownHour:           'Stunde',
    countDownHours:          'Stunden',
    countDownMinute:         'Minute',
    countDownMinutes:        'Minuten',
    countDownSecond:         'Sekunde',
    countDownSeconds:        'Sekunden',
    flashTable:              'Blitztabelle',
    qualifyingTitle:         'Qualifying',
    notQualified:            'Not qualified',
    themeTeam:               'Team',
    game:                    'Spiel',
    gameday:                 'Spieltag',
    gameLogo:                'Spiel-Logo',
    goals:                   'Tore',
    home:                    'Übersicht',
    meter:                   'Meter',
    more:                    'Mehr',
    oclock:                  'Uhr',
    quote:                   'Quote',
    or:                      'oder',
    pageTitle:               'TauRes SalesGame',
    parallelGames:           'Parallel-Spiele',
    points:                  'Punkte',
    pointsTotal:             'Gesamtpunkte',
    rank:                    'Rang',
    rounds:                  'Runden',
    runs:                    'Läufe',
    service:                 'Service',
    squad:                   'Kader',
    statistic:               'Statistik',
    sum:                     'Gesamt',
    t1Short:                 'T1',
    t2Short:                 'T2',
    t3Short:                 'T3',
    t3TnShort:               'T3+Tn',
    taures:                  'TauRes',
    team:                    'Mannschaft',
    tendency:                'Tendenz',
    time:                    'Zeit',
    ticker:                  'Ticker',
    tickerDateFormat:        'HH:mm - DD.MM.YY',
    trend:                   'Trend',
    tvp:                     'T/VP',
    versionNumberDateFormat: 'DD.MM.YYYY HH:mm:ss',
};

module.exports = german;
