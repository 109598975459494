//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import RowType           from '../components/stateless/Table/RowType';
import Cast              from './Cast';
import _                 from 'lodash';
import moment            from 'moment';
import I18n              from 'i18next';
import Routes            from '../constants/Routes';
import FirebaseEndpoints from '../constants/FirebaseEndpoints';
import TeamImage         from '../components/stateless/TeamLogo/TeamImage';

const history = {};

class FirebaseDataTransformer {
    static applyChangeSet (endpoint, newData) {
        const dataWithChangeSetFlags = _.clone(newData);

        if (history[endpoint]) {
            for (let index in newData) {
                const changed = (
                    history[endpoint] &&
                    history[endpoint][index] &&
                    !_.isEqual(
                        history[endpoint][index].cellData,
                        newData[index].cellData,
                    )
                );

                if (changed) {
                    // We set the current time since the table is re-rendered multiple times
                    // so we cannot toggle a boolean because it would be overwritten after
                    // a few milliseconds.
                    const nowUnixTime                            = moment().unix();
                    dataWithChangeSetFlags[index].lastChangeDate = nowUnixTime;

                    // The following code also updates the corresponding group member
                    // if the row is identified as part of a group (this is the case
                    // when "groupStart" or "groupEnd" is set.
                    if (dataWithChangeSetFlags[index].groupStart) {
                        const nextIndex                                  = Cast.int(index) + 1;
                        dataWithChangeSetFlags[nextIndex].lastChangeDate = nowUnixTime;
                    } else if (dataWithChangeSetFlags[index].groupEnd) {
                        const previousIndex                                  = Cast.int(index) - 1;
                        dataWithChangeSetFlags[previousIndex].lastChangeDate = nowUnixTime;
                    }
                } else if (
                    history[endpoint] &&
                    history[endpoint][index] &&
                    history[endpoint][index].lastChangeDate
                ) {
                    dataWithChangeSetFlags[index].lastChangeDate = history[endpoint][index].lastChangeDate;
                }
            }
        }

        history[endpoint] = dataWithChangeSetFlags;

        return newData;
    }

    static beautifyAppointmentType (appointmentType) {
        switch (appointmentType) {
            // @formatter:off
            case 'service': return I18n.t('service');
            case 't1':      return I18n.t('t1Short');
            case 't2':      return I18n.t('t2Short');
            case 't3':      return I18n.t('t3Short');
            // @formatter:on
        }

        // Fallback to the input value
        return appointmentType;
    }

    static beautifyGame (game) {
        if (game) {
            game               = game.replace('spiel', 'Spiel ');
            const splittedGame = game.split(' ');
            splittedGame[1]    = Cast.int(splittedGame[1]);

            return splittedGame.join(' ');
        }

        return game;
    }

    static beautifyQuote (quote) {
        if (quote > 0) {
            return quote.toFixed(2);
        }

        return '0';
    }

    static beautifyTendency (tendeny) {
        if (tendeny > 0) {
            return '➚';
        } else if (tendeny < 0) {
            return '➘';
        }

        return '➙';
    }

    static getTeamForRank (rank) {
        return 'to qualify';

        switch (rank) {
            case 1:
                return 'America';
            case 2:
                return 'Magic';
            case 3:
                return 'Madleine';
            case 4:
                return 'Mischief';
            case 5:
                return 'Puritan';
            case 6:
                return 'Mayflower';
            case 7:
                return 'Volunteer';
            case 8:
                return 'Vigilant';
            case 9:
                return 'Defender';
            case 10:
                return 'Columbia';
            case 11:
                return 'Reliance';
            case 12:
                return 'Resolute';
            case 13:
                return 'Enterprise';
            case 14:
                return 'Rainbow';
            case 15:
                return 'Ranger';
            case 16:
                return 'Weatherly';
            case 17:
                return 'Constellation';
            case 18:
                return 'Intrepid';
            case 19:
                return 'Courageous';
            case 20:
                return 'Freedom';
            case 21:
                return 'Liberty';
            case 22:
                return 'Kookaburra III';
            case 23:
                return 'Stars & Stripes';
            case 24:
                return 'Young America';
            case 25:
                return 'New Zealand';
            case 26:
                return 'Team New Zealand';
            case 27:
                return 'Alinghi';
            case 28:
                return 'Te Rehutai';
            case 29:
                return 'Cambria';
            case 30:
                return 'Livonia';
            case 31:
                return 'Countess of Dufferin';
            case 32:
                return 'Atalanta';
            case 33:
                return 'Aurora';
            case 34:
                return 'Genesta';
            case 35:
                return 'Galatea';
            case 36:
                return 'Thistle';
            case 37:
                return 'Alarm';
            case 38:
                return 'Arrow';
            case 39:
                return 'Valkyrie';
            case 40:
                return 'Shamrock';
            case 41:
                return 'Endeavour';
            case 42:
                return 'Gretel';
            case 43:
                return 'Sovereign';
            case 44:
                return 'Dame Pattie';
            case 45:
                return 'Southern Cross';
            case 46:
                return 'Australia';
            case 47:
                return 'Il Moro di Venezia';
            case 48:
                return 'Black Magic';
            case 49:
                return 'Luna Rossa';
            case 50:
                return 'Emirates Team New Zealand';
            case 51:
                return 'Aotearoa';
            case 52:
                return 'USA 17';
        }
    }

    static getTeamImageForRank (rank) {
        switch (rank) {
            case 1:
            case 2:
            case 3:
                return TeamImage.telekom;

            case 4:
            case 5:
            case 6:
                return TeamImage.movistar;

            case 7:
            case 8:
            case 9:
                return TeamImage.tMobile;

            case 10:
            case 11:
            case 12:
                return TeamImage.trekSegafredo;

            case 13:
            case 14:
            case 15:
                return TeamImage.astana;

            case 16:
            case 17:
            case 18:
                return TeamImage.ag2r;

            case 19:
            case 20:
            case 21:
                return TeamImage.boraHansgrohe;

            case 22:
            case 23:
            case 24:
                return TeamImage.ccc;

            case 25:
            case 26:
            case 27:
                return TeamImage.usPostal;

            case 28:
            case 29:
            case 30:
                return TeamImage.kelme;

            case 31:
            case 32:
            case 33:
                return TeamImage.cofidis;

            case 34:
            case 35:
            case 36:
                return TeamImage.kas;

            case 37:
            case 38:
            case 39:
                return TeamImage.orbea;

            case 40:
            case 41:
            case 42:
                return TeamImage.mapei;

            case 43:
            case 44:
            case 45:
                return TeamImage.saeco;

            case 46:
            case 47:
            case 48:
                return TeamImage.once;

            case 49:
            case 50:
            case 51:
                return TeamImage.creditAgricole;

            case 52:
            case 53:
            case 54:
                return TeamImage.lottoSoudal;

            case 55:
            case 56:
            case 57:
                return TeamImage.ineosBianchi;
        }

        return null;
    }

    static transformTeamData (teamData) {
        const data = [];

        for (const entryIndex in teamData) {
            const entry     = teamData[entryIndex];
            const entryData = entry.data;
            console.log('entry', entry);

            data.push({
                cellData:  [
                    {
                        text: Cast.int(entryIndex) + 1,
                    },
                    {
                        //preImage: FirebaseDataTransformer.getTeamImageForRank(entryData.rang_neu),
                        text: FirebaseDataTransformer.getTeamForRank(entryData.rang_neu),
                    },
                    {
                        text: entryData.mannschaft,
                    },
                    {
                        text: entryData.termine,
                    },
                    {
                        text: FirebaseDataTransformer.beautifyQuote(entryData.quote),
                    },
                    {
                        text: FirebaseDataTransformer.beautifyTendency(entryData.tendenz),
                    },
                ],
                qualified: entryData.qualified,
                type:      RowType.text,
            });
        }

        return FirebaseDataTransformer.applyChangeSet(
            FirebaseEndpoints.teams,
            data,
        );
    }

    // TODO: andere hier entfernen
    static transformFlashTableData (firebaseResponse) {
        const flashTableData = [];

        for (const firebaseKey in firebaseResponse.value) {
            if (firebaseKey === 'null') {
                continue;
            }

            const firebaseValue = firebaseResponse.value[firebaseKey];
            const points        = Cast.int(firebaseValue);

            flashTableData.push({
                cellData: [
                    {
                        text: firebaseKey,
                    },
                    {
                        text: points,
                    },
                ],
                points,
                type:     RowType.text,
            });
        }

        const sortedFlashTableData = _.sortBy(
            flashTableData,
            ['points'],
        ).reverse();

        return sortedFlashTableData;
    }

    static transformParallelGamesData (firebaseResponse, selectedGame, selectedMatch) {
        const parallelGamesData = [];

        for (const firebaseKey in firebaseResponse.value[selectedGame]) {
            if (firebaseKey.indexOf('null') > -1) {
                continue;
            }

            const firebaseValue = firebaseResponse.value[selectedGame][firebaseKey];

            if (
                !firebaseValue.standort1 ||
                !firebaseValue.standort2
            ) {
                continue;
            }

            firebaseValue.match = firebaseKey;
            const selected      = selectedMatch === firebaseKey;
            const targetRoute   = {
                route:      Routes.match,
                parameters: {
                    ':match': firebaseKey,
                },
            };

            if (parallelGamesData.length > 0) {
                parallelGamesData.push({
                    type: RowType.dash,
                });
            }

            parallelGamesData.push({
                cellData:   [
                    {
                        text: firebaseValue.standort1,
                    },
                    {
                        text: Cast.int(firebaseValue.meter1),
                    },
                    {
                        text: Cast.int(firebaseValue.anzahlTore1),
                    },
                ],
                groupStart: true,
                matchData:  firebaseValue,
                selected,
                targetRoute,
                type:       RowType.text,
            });

            parallelGamesData.push({
                cellData:  [
                    {
                        text: firebaseValue.standort2,
                    },
                    {
                        text: Cast.int(firebaseValue.meter2),
                    },
                    {
                        text: Cast.int(firebaseValue.anzahlTore2),
                    },
                ],
                groupEnd:  true,
                matchData: firebaseValue,
                selected,
                targetRoute,
                type:      RowType.text,
            });
        }

        return FirebaseDataTransformer.applyChangeSet(
            FirebaseEndpoints.parallelGames,
            parallelGamesData,
        );
    }

    static transformTickerData (firebaseResponse) {
        const tickerData = [];

        for (const firebaseKey in firebaseResponse.value) {
            if (firebaseKey === 'null') {
                continue;
            }

            const firebaseValue = firebaseResponse.value[firebaseKey];

            if (!firebaseValue.vp) {
                continue;
            }

            const time     = moment(firebaseValue.timeStamp);
            const timeUnix = time.unix();

            tickerData.push({
                cellData: [
                    {
                        text: firebaseValue.vp,
                    },
                    {
                        text: FirebaseDataTransformer.beautifyAppointmentType(firebaseValue.terminTyp),
                    },
                    {
                        text: FirebaseDataTransformer.beautifyGame(firebaseValue.spiel),
                    },
                    {
                        text: firebaseValue.mannschaft,
                    },
                    {
                        text: time.format(I18n.t('tickerDateFormat')),
                    },
                ],
                timeUnix,
                type:     RowType.text,
            });
        }

        const sortedTickerData = _.sortBy(
            tickerData,
            ['timeUnix'],
        ).reverse();

        return FirebaseDataTransformer.applyChangeSet(
            FirebaseEndpoints.ticker,
            sortedTickerData,
        );
    }

    static transformSquadData (firebaseResponse) {
        const squadsData = [];

        for (const firebaseKey in firebaseResponse.value) {
            if (firebaseKey === 'null') {
                continue;
            }

            const firebaseValue = firebaseResponse.value[firebaseKey];
            const service       = Cast.int(firebaseValue.Service);
            const T1            = Cast.int(firebaseValue.T1);
            const T2            = Cast.int(firebaseValue.T2);
            const T3Tn          = (
                Cast.int(firebaseValue.T3) +
                Cast.int(firebaseValue.Tn)
            );
            const sum           = Cast.int(firebaseValue.SummeTermine);

            squadsData.push({
                cellData: [
                    {
                        text: firebaseValue.vpName,
                    },
                    {
                        text: firebaseValue.mannschaft,
                    },
                    {
                        text: T1,
                    },
                    {
                        text: T2,
                    },
                    {
                        text: T3Tn,
                    },
                    {
                        text: service,
                    },
                    {
                        text: sum,
                    },
                ],
                sum:      sum,
                type:     RowType.text,
            });
        }

        const sortedSquadsData = _.sortBy(
            squadsData,
            ['sum'],
        ).reverse();

        return FirebaseDataTransformer.applyChangeSet(
            FirebaseEndpoints.squads,
            sortedSquadsData,
        );
    }

    static transformStatisticsData (firebaseResponse) {
        const statisticsData = [];

        for (const firebaseKey in firebaseResponse.value) {
            if (firebaseKey === 'null') {
                continue;
            }

            const firebaseValue = firebaseResponse.value[firebaseKey];
            const service       = Cast.int(firebaseValue.service);
            const T1            = Cast.int(firebaseValue.T1);
            const T2            = Cast.int(firebaseValue.T2);
            const T3Tn          = (
                Cast.int(firebaseValue.T3) +
                Cast.int(firebaseValue.Tn)
            );
            const sum           = _.sum([
                0,
                T1,
                T2,
                T3Tn,
                service,
            ]);

            statisticsData.push({
                cellData: [
                    {
                        text: firebaseKey,
                    },
                    {
                        text: T1,
                    },
                    {
                        text: T2,
                    },
                    {
                        text: T3Tn,
                    },
                    {
                        text: service,
                    },
                    {
                        text: sum,
                    },
                ],
                sum:      sum,
                type:     RowType.text,
            });
        }

        const sortedStatisticsData = _.sortBy(
            statisticsData,
            ['sum'],
        ).reverse();

        return FirebaseDataTransformer.applyChangeSet(
            FirebaseEndpoints.teams,
            sortedStatisticsData,
        );
    }
}

export default FirebaseDataTransformer;
