import FirebaseEndpoints from '../constants/FirebaseEndpoints';
import firebase          from 'firebase/app';
import 'firebase/firestore';

class FirebaseDataProvider {
    static mapData (document) {
        return {
            id:   document.id,
            data: document.data(),
        };
    }

    static getTeamData (dataChangedCallback) {
        const firestore = firebase.firestore();

        firestore.collection(FirebaseEndpoints.teams).orderBy('rang_neu', 'asc').onSnapshot((snapshot) => {
            const teamData = (
                snapshot.docs.map(FirebaseDataProvider.mapData)
            );

            dataChangedCallback(teamData);
        });
    }
}

export default FirebaseDataProvider;