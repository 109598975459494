//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper     from '../../../helper/ComponentHelper';
import I18n                from 'i18next';
import React               from 'react';
import styles              from './styles.module.scss';
import CustomCountDown     from '../CustomCountDown';
import VersionNumber       from '../VersionNumber';
import DebugCounterWrapper from '../../connected/DebugCounterWrapper';

class Header extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render () {
        return (
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={styles.left}>
                        <CustomCountDown
                            toTimeStamp={1638313140}
                        />
                    </div>
                    <div className={styles.center}>
                        <div className={styles.headerBadge}>
                            <img
                                src={require('../../../assets/images/bundesliga/bundesliga-logo.png')}
                            />
                        </div>
                    </div>
                    <div className={styles.right}>
                        <DebugCounterWrapper>
                            <VersionNumber />
                        </DebugCounterWrapper>
                    </div>
                </div>
            </div>
        );
    }
}

const Component = Header;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = [];

Component.renderAffectingStates = [];

export default Component;
